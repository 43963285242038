"use client";

import * as React from "react";
import { BarChart, CreditCard, Clock, Check } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle, CardFooter } from "@/components/ui/card";
import { SignedIn, SignedOut, SignInButton, SignUpButton, useUser } from "@clerk/nextjs";
import { AboutSection } from "@/components/common/about";
export default function Component() {
  //const { setTheme, theme } = useTheme(); // eslint-disable-line @typescript-eslint/no-unused-vars
  const userObject = useUser();
  const user = userObject.user;
  return <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white dark:from-gray-900 dark:to-gray-800" data-sentry-component="Component" data-sentry-source-file="page.tsx">
      <main className="container mx-auto px-4 py-16">
        <section className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-500">
            Keep your productivity uninterrupted with moshizen
          </h2>
          <p className="text-xl text-muted-foreground mb-8">
            Let our AI assistant answer your calls, so you can focus on growing
            your business.
          </p>
          <Button size="lg" className="rounded-full" data-sentry-element="Button" data-sentry-source-file="page.tsx" data-sentry-element="Button">
            <a href="/#pricing">Get Started Now</a>
          </Button>
        </section>

        <section className="mb-16">
          <h3 className="text-3xl font-bold text-center mb-12">
            What moshizen can do for you
          </h3>
          <div className="grid md:grid-cols-3 gap-8">
            <FeatureCard icon={<Clock className="w-10 h-10 text-purple-500" />} title="More time to focus on your business" description="Let moshizen handle your calls, so you can focus on growing your business. Just refer cold callers to speak with your AI, all the way from picking up to figuring out the caller's purpose and aligning with your preferences." data-sentry-element="FeatureCard" data-sentry-source-file="page.tsx" data-sentry-element="FeatureCard" />
            <FeatureCard icon={<BarChart className="w-10 h-10 text-purple-500" />} title="Comprehensive Analytics" description="Tools to keep track of how moshizen is handling your calls. You get a full dashboard of past performance and insights into how your instructions are working, with full history of contacts. No more 'Oh I forgot so-and-so called!'" data-sentry-element="FeatureCard" data-sentry-source-file="page.tsx" data-sentry-element="FeatureCard" />
            <FeatureCard icon={<CreditCard className="w-10 h-10 text-purple-500" />} title="Straightforward Pricing" description="We have a pricing model that makes sense and scales with your business. Try getting someone to pick up the phone 24/7 just for $0.05 per entire call." data-sentry-element="FeatureCard" data-sentry-source-file="page.tsx" data-sentry-element="FeatureCard" />
          </div>
        </section>

        <section className="mb-16" id="pricing">
          <h3 className="text-3xl font-bold text-center mb-12">
            Simple Pricing
          </h3>
          <div className="grid md:grid-cols-3 gap-8">
            <PricingCard title="Starter" price="$9" description="Perfect for individuals" actionUrl={`https://buy.stripe.com/fZecQ85wi7SheAMdQV?prefilled_email=${user?.primaryEmailAddress}&prefilled_phone=${user?.primaryPhoneNumber}`} features={["20 screened calls per month", "Custom instructions", "0.10$ per call extra", "Email transcripts", "Customer Portal", "Analytics", "30-day history", "Email support"]} data-sentry-element="PricingCard" data-sentry-source-file="page.tsx" data-sentry-element="PricingCard" />
            <PricingCard title="Pro" price="$19" description="Ideal for busy executives" actionUrl={`https://buy.stripe.com/fZecQ85wi7SheAMdQV?prefilled_email=${user?.primaryEmailAddress}&prefilled_phone=${user?.primaryPhoneNumber}`} features={["50 screened calls per month", "Custom instructions", "0.05$ per call extra", "Email transcripts", "Customer Portal", "Analytics", "Unlimited history", "Priority email support"]} highlighted={true} data-sentry-element="PricingCard" data-sentry-source-file="page.tsx" data-sentry-element="PricingCard" />
            <PricingCard title="Enterprise" price="Custom" description="For large-scale deployments" actionUrl="https://maas-solutions.fi/contact" features={["Custom limits", "Custom instructions", "Custom greeting", "Premium numbers", "Email transcripts", "Customer Portal with SSO", "Full analytics suite", "Custom integration", "Priority support"]} data-sentry-element="PricingCard" data-sentry-source-file="page.tsx" data-sentry-element="PricingCard" />
          </div>
        </section>

        <AboutSection data-sentry-element="AboutSection" data-sentry-source-file="page.tsx" data-sentry-element="AboutSection" />

        <Card className="text-center bg-purple-100 dark:bg-gray-800" data-sentry-element="Card" data-sentry-source-file="page.tsx" data-sentry-element="Card">
          <CardHeader data-sentry-element="CardHeader" data-sentry-source-file="page.tsx" data-sentry-element="CardHeader">
            <CardTitle className="text-3xl font-bold" data-sentry-element="CardTitle" data-sentry-source-file="page.tsx" data-sentry-element="CardTitle">
              Ready to Elevate Your Business?
            </CardTitle>
            <CardDescription className="text-xl" data-sentry-element="CardDescription" data-sentry-source-file="page.tsx" data-sentry-element="CardDescription">
              Join thousands of satisfied customers and take your business to
              the next level.
            </CardDescription>
          </CardHeader>
          <CardContent data-sentry-element="CardContent" data-sentry-source-file="page.tsx" data-sentry-element="CardContent">
            <SignUpButton data-sentry-element="SignUpButton" data-sentry-source-file="page.tsx" data-sentry-element="SignUpButton" />
          </CardContent>
        </Card>
      </main>
    </div>;
}
interface FeatureCardProps {
  icon: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  title: string;
  description: string;
}
function FeatureCard({
  icon,
  title,
  description
}: FeatureCardProps) {
  return <Card data-sentry-element="Card" data-sentry-component="FeatureCard" data-sentry-source-file="page.tsx">
      <CardHeader data-sentry-element="CardHeader" data-sentry-source-file="page.tsx" data-sentry-element="CardHeader">
        <div className="mb-4">{icon}</div>
        <CardTitle data-sentry-element="CardTitle" data-sentry-source-file="page.tsx" data-sentry-element="CardTitle">{title}</CardTitle>
      </CardHeader>
      <CardContent data-sentry-element="CardContent" data-sentry-source-file="page.tsx" data-sentry-element="CardContent">
        <CardDescription data-sentry-element="CardDescription" data-sentry-source-file="page.tsx" data-sentry-element="CardDescription">{description}</CardDescription>
      </CardContent>
    </Card>;
}
interface PricingCardProps {
  title: string;
  price: string | number;
  description: string;
  features: string[];
  highlighted?: boolean;
  actionUrl?: string;
}
function PricingCard({
  title,
  price,
  description,
  features,
  highlighted = false,
  actionUrl = "/signup"
}: PricingCardProps) {
  return <Card className={`flex flex-col ${highlighted ? "border-purple-500 border-2" : ""}`} data-sentry-element="Card" data-sentry-component="PricingCard" data-sentry-source-file="page.tsx">
      <CardHeader data-sentry-element="CardHeader" data-sentry-source-file="page.tsx" data-sentry-element="CardHeader">
        <CardTitle className="text-2xl font-bold" data-sentry-element="CardTitle" data-sentry-source-file="page.tsx" data-sentry-element="CardTitle">{title}</CardTitle>
        <CardDescription data-sentry-element="CardDescription" data-sentry-source-file="page.tsx" data-sentry-element="CardDescription">{description}</CardDescription>
      </CardHeader>
      <CardContent className="flex-grow" data-sentry-element="CardContent" data-sentry-source-file="page.tsx" data-sentry-element="CardContent">
        <p className="text-4xl font-bold mb-4">
          {price}
          <span className="text-xl font-normal">/mo</span>
        </p>
        <ul className="space-y-2">
          {features.map((feature, index) => <li key={index} className="flex items-center">
              <Check className="w-5 h-5 text-green-500 mr-2" />
              {feature}
            </li>)}
        </ul>
      </CardContent>
      <CardFooter data-sentry-element="CardFooter" data-sentry-source-file="page.tsx" data-sentry-element="CardFooter">
        <Button className={`w-full ${highlighted ? "bg-purple-500 hover:bg-purple-600" : ""}`} data-sentry-element="Button" data-sentry-source-file="page.tsx" data-sentry-element="Button">
          <SignedIn data-sentry-element="SignedIn" data-sentry-source-file="page.tsx" data-sentry-element="SignedIn">
            <a href={actionUrl} className="w-full h-full flex items-center justify-center">
              Choose Plan
            </a>
          </SignedIn>
          <SignedOut data-sentry-element="SignedOut" data-sentry-source-file="page.tsx" data-sentry-element="SignedOut">
            <SignInButton data-sentry-element="SignInButton" data-sentry-source-file="page.tsx" data-sentry-element="SignInButton">
              <a href={actionUrl} className="w-full h-full flex items-center justify-center">
                Choose Plan
              </a>
            </SignInButton>
          </SignedOut>
        </Button>
      </CardFooter>
    </Card>;
}